import { useReactiveVar } from '@apollo/client';
import { memo, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { newAlertVar } from '~/apollo/reactiveVariables/newAlertVar';
import ControlSidebar from '~/components/ControlSidebar';
import DashboardOverlayTopLeft from '~/components/DashboardOverlayTopLeft';
import MainMap from '~/components/maps/MainMap';
import {
  DEFAULT_GPS_POSITION,
  DEFAULT_MAP_ZOOM_LEVEL,
  CARRIER_MAP_ZOOM_LEVEL,
} from '~/config/defaults';
import routes from '~/config/routes';
import useAgentsContext from '~/context/useAgentsContext';
import useAgentStatuses from '~/hooks/useAgentStatuses';
import useInitialLoading from '~/hooks/useInitialLoading';
import usePreventPageScroll from '~/hooks/usePreventPageScroll';
import useQueryParams from '~/hooks/useQueryParams';
import useSubsidiary from '~/hooks/useSubsidiary';
import useTeams from '~/hooks/useTeams';
import theme from '~/theme';
import type { GpsCoordinates } from '~/types/sensor';

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 280px) minmax(0, 1fr);

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const Main = styled.main`
  position: relative;
`;

const MapPage = memo(() => {
  const query = useQueryParams();
  const navigate = useNavigate();

  const { resetAgentStatuses } = useAgentStatuses();
  const { setSelectedTeams } = useTeams();
  const { carrierId } = useReactiveVar(newAlertVar);
  const { isInitialLoading } = useInitialLoading();
  const { getAgent, getVehicle } = useAgentsContext();
  const { currentSubsidiary } = useSubsidiary();

  usePreventPageScroll();

  const maybeVehicleOrAgentPosition = useMemo(
    () =>
      getVehicle(query.vehicle ?? '')?.gps?.value || getAgent(query.id ?? '')?.sensors?.gps?.value,
    [getAgent, getVehicle, query.id, query.vehicle],
  );

  const centerPosition: GpsCoordinates = useMemo(
    () => maybeVehicleOrAgentPosition || currentSubsidiary?.location || DEFAULT_GPS_POSITION,
    [currentSubsidiary?.location, maybeVehicleOrAgentPosition],
  );

  const zoom = useMemo(() => {
    const defaultZoom = query.id ? CARRIER_MAP_ZOOM_LEVEL : DEFAULT_MAP_ZOOM_LEVEL;

    return centerPosition !== DEFAULT_GPS_POSITION ? defaultZoom : DEFAULT_MAP_ZOOM_LEVEL;
  }, [centerPosition, query.id]);

  useEffect(() => {
    if (carrierId) {
      navigate(routes.map({ id: carrierId }));
    }
  }, [navigate, carrierId]);

  useEffect(() => {
    if (query.id || carrierId) {
      setSelectedTeams([]);
      resetAgentStatuses();
    }
  }, [carrierId, query.id, resetAgentStatuses, setSelectedTeams]);

  return (
    <GridDiv>
      <ControlSidebar idFromQuery={query.id} vehicleFromQuery={query.vehicle} />
      <Main>
        <MainMap
          key={`${currentSubsidiary?.id}${isInitialLoading}${JSON.stringify(query)}`}
          isLoading={isInitialLoading}
          isBlurred={false}
          initialZoom={zoom}
          initialLat={centerPosition.lat}
          initialLng={centerPosition.lng}
          followingZoom={undefined}
          followingLat={undefined}
          followingLng={undefined}
          isFocusedOnAgent={!!query?.id}
        />
        <DashboardOverlayTopLeft />
      </Main>
    </GridDiv>
  );
});

MapPage.displayName = 'MapPage';

export default MapPage;
