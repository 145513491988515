import ApiOutlined from '@ant-design/icons/ApiOutlined';
import { Alert, AutoComplete, Button, Form, Input, List, Select, Tooltip } from 'antd';
import orderBy from 'lodash/orderBy';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import useMutationAssociateBrainToAgent from '~/apollo/hooks/brain/useMutationAssociateBrainToAgent';
import useMutationRequestAssociationBrainToAgent from '~/apollo/hooks/brain/useMutationRequestAssociationBrainToAgent';
import type { QueryAllBrainsOutput } from '~/apollo/hooks/brain/useQueryAllBrains';
import SettingsSelectCompany from '~/components/settings/components/SettingsSelectCompany';
import SettingsSelectSubsidiary from '~/components/settings/components/SettingsSelectSubsidiary';
import useAgentsContext from '~/context/useAgentsContext';
import useCompany from '~/hooks/useCompany';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import notification from '~/utils/notification';
import sleep from '~/utils/sleep';

const BottomFlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface FormValues {
  deviceName: string;
  agentId: string;
}

export interface Props {
  allBrains: QueryAllBrainsOutput['getAllDevices']['items'];
  initialValues?: Partial<FormValues>;
  onSuccess: () => Promise<void>;
  onClose?: () => void;
}

const BrainAssociateToAgentForm = memo(
  ({ allBrains, initialValues, onSuccess, onClose }: Props) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<
      'form' | 'confirmationRequestAssociation' | 'confirmationForceAssociation'
    >('form');
    const { currentCompany } = useCompany();
    const { currentSubsidiary } = useSubsidiary();

    const {
      agents,
      isInitialLoading: isAgentsInitialLoading,
      isLoading: isAgentsLoading,
    } = useAgentsContext();

    const { requestAssociationBrainToAgent, isRequestAssociationBrainToAgentLoading } =
      useMutationRequestAssociationBrainToAgent();

    const { associateBrainToAgent, isAssociateBrainToAgentLoading } =
      useMutationAssociateBrainToAgent();

    const isLoading =
      isSubmitting ||
      isAgentsInitialLoading ||
      isAgentsLoading ||
      isRequestAssociationBrainToAgentLoading ||
      isAssociateBrainToAgentLoading;

    const [form] = Form.useForm<FormValues>();

    const deviceName = Form.useWatch('deviceName', form);
    const agentId = Form.useWatch('agentId', form);

    const selectedAgent = useMemo(
      () => agents.find((agent) => agent.id === agentId),
      [agents, agentId],
    );

    useEffect(() => {
      form.setFieldValue('agentId', undefined);
    }, [currentCompany?.id, currentSubsidiary?.id, form]);

    const [autoCompleteText, setAutoCompleteText] = useState<string>(deviceName || '');

    const agentOptions = useMemo(
      () =>
        orderBy(
          agents.map((agent) => {
            const label = `${agent.completeName}${agent.email ? ` (${agent.email})` : ''}`;
            return {
              value: agent.id,
              label,
              labelNormalized: label.toLocaleLowerCase(),
              disabled: !!agent.deviceName,
            };
          }),
          ['labelNormalized'],
          ['asc'],
        ),
      [agents],
    );

    const brainOptions = useMemo(
      () =>
        orderBy(
          allBrains
            .filter((brain) => brain.name.toLowerCase().includes(autoCompleteText))
            .map((brain) => ({
              label: brain.name,
              value: brain.name,
              labelLowerCase: brain.name.toLowerCase(),
              disabled: !!brain.carrier?.id,
            })),
          ['labelLowerCase'],
          ['asc'],
        ),
      [allBrains, autoCompleteText],
    );

    const hasDeviceError = useMemo(() => {
      if (!deviceName) {
        return false;
      }
      const foundBrain = allBrains.find((d) => d.name === deviceName);
      return !foundBrain || !!foundBrain.carrier?.id;
    }, [allBrains, deviceName]);

    const handleSubmit = useCallback(
      async ({ type }: { type: 'requestAssociation' | 'forceAssociation' }) => {
        setIsSubmitting(true);
        try {
          if (type === 'forceAssociation') {
            await associateBrainToAgent({
              variables: {
                device_name: deviceName,
                carrier_id: agentId,
              },
            });
          } else {
            await requestAssociationBrainToAgent({
              variables: {
                device_name: deviceName,
                carrier_id: agentId,
              },
            });
          }
          await sleep(5_000);
          onClose?.();
          notification.success({
            message: i18n.t('common.success'),
            description: i18n.t('backofficePage.brainsTab.brainAssociatedSuccessfully'),
            duration: 10,
          });
          await onSuccess();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          notification.error({
            message: i18n.t('common.error'),
            description: (error as any)?.message,
          });
        } finally {
          setIsSubmitting(false);
        }
      },
      [
        requestAssociationBrainToAgent,
        associateBrainToAgent,
        onClose,
        onSuccess,
        agentId,
        deviceName,
      ],
    );

    const isButtonDisabled =
      hasDeviceError || !deviceName || !currentCompany?.id || !currentSubsidiary?.id || !agentId;

    const isConfirmationStep =
      currentStep === 'confirmationRequestAssociation' ||
      currentStep === 'confirmationForceAssociation';

    return (
      <Form
        form={form}
        layout="vertical"
        validateTrigger="onBlur"
        initialValues={{
          ...initialValues,
          companyId: currentCompany?.id,
          subsidiaryId: currentSubsidiary?.id,
        }}
        autoComplete="off"
      >
        <div style={{ display: currentStep === 'form' ? 'block' : 'none' }}>
          <Alert
            type="info"
            message={i18n.t('backofficePage.brainsTab.warningMessageForm')}
            style={{
              fontStyle: 'italic',
              marginBottom: '16px',
            }}
          />
          <Form.Item name="deviceName" label={i18n.t('common.brain')}>
            <AutoComplete
              options={brainOptions}
              onChange={(value) => {
                setAutoCompleteText(value?.trim()?.toLowerCase() || '');
              }}
              placeholder={i18n.t<string>('common.brain')}
              disabled={isLoading}
              showAction={['focus']}
              onBlur={() => {
                setAutoCompleteText('');
              }}
            >
              <Input
                type="text"
                required
                style={{ borderColor: hasDeviceError ? 'red' : undefined }}
              />
            </AutoComplete>
          </Form.Item>
          <Form.Item label={i18n.t('common.company')}>
            <SettingsSelectCompany disabled={isLoading} />
          </Form.Item>
          <Form.Item label={i18n.t('common.subsidiary')}>
            <SettingsSelectSubsidiary disabled={isLoading || !currentCompany?.id} />
          </Form.Item>
          <Form.Item name="agentId" label={i18n.t('common.agent')}>
            <Select
              disabled={isLoading || !currentCompany?.id || !currentSubsidiary?.id}
              options={agentOptions}
              placeholder={i18n.t<string>('common.agent')}
              onChange={(value) => {
                form.setFieldValue('agentId', value);
              }}
            />
          </Form.Item>
        </div>
        {isConfirmationStep && (
          <>
            <Alert
              type={currentStep === 'confirmationForceAssociation' ? 'error' : 'warning'}
              message={
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html:
                      currentStep === 'confirmationRequestAssociation'
                        ? i18n.t<string>(
                            'backofficePage.brainsTab.warningMessageConfirmationRequestHtml',
                            {
                              brain: deviceName,
                            },
                          )
                        : `${i18n.t(
                            'backofficePage.brainsTab.warningMessageConfirmationForceHtml',
                            {
                              brain: deviceName,
                            },
                          )} ${i18n.t('backofficePage.brainsTab.forceTooltip')}`,
                  }}
                />
              }
              style={{
                fontStyle: 'italic',
                marginBottom: '16px',
              }}
            />
            <List
              style={{
                marginBottom: '16px',
              }}
              size="small"
              bordered
              dataSource={[
                <>
                  <b>{i18n.t('common.brain')}</b> {deviceName}
                </>,
                <>
                  <b>{i18n.t('common.company')}</b> {currentCompany?.name}
                </>,
                <>
                  <b>{i18n.t('common.subsidiary')}</b> {currentSubsidiary?.name}
                </>,
                <>
                  <b>{i18n.t('common.agent')}</b> {selectedAgent?.completeName}
                  {selectedAgent?.email ? ` (${selectedAgent?.email})` : ''}
                </>,
              ]}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </>
        )}
        <BottomFlexDiv>
          {currentStep === 'form' && (
            <>
              <Button size="middle" disabled={isLoading} onClick={onClose}>
                {i18n.t('common.cancel')}
              </Button>
              <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                <Tooltip placement="bottom" title={i18n.t('backofficePage.brainsTab.forceTooltip')}>
                  <Button
                    size="middle"
                    type="primary"
                    danger
                    disabled={isButtonDisabled}
                    loading={isLoading}
                    icon={<ApiOutlined />}
                    onClick={() => {
                      setCurrentStep('confirmationForceAssociation');
                    }}
                  >
                    {i18n.t('backofficePage.brainsTab.forceBrainAssociation')}
                  </Button>
                </Tooltip>
                <Button
                  size="middle"
                  type="primary"
                  disabled={isButtonDisabled}
                  loading={isLoading}
                  icon={<ApiOutlined />}
                  onClick={() => {
                    setCurrentStep('confirmationRequestAssociation');
                  }}
                >
                  {i18n.t('backofficePage.brainsTab.requestBrainAssociation')}
                </Button>
              </div>
            </>
          )}
          {isConfirmationStep && (
            <>
              <Button
                size="middle"
                disabled={isLoading}
                onClick={() => {
                  setCurrentStep('form');
                }}
              >
                {i18n.t('common.no')}
              </Button>
              <Button
                size="middle"
                type="primary"
                danger={currentStep === 'confirmationForceAssociation'}
                disabled={isButtonDisabled}
                loading={isLoading}
                onClick={async () => {
                  if (currentStep === 'confirmationRequestAssociation') {
                    await handleSubmit({ type: 'requestAssociation' });
                  } else {
                    await handleSubmit({ type: 'forceAssociation' });
                  }
                }}
              >
                {currentStep === 'confirmationRequestAssociation'
                  ? i18n.t('backofficePage.brainsTab.yesBrainIsOnline')
                  : i18n.t('common.yes')}
              </Button>
            </>
          )}
        </BottomFlexDiv>
      </Form>
    );
  },
);

BrainAssociateToAgentForm.displayName = 'BrainAssociateToAgentForm';

export default BrainAssociateToAgentForm;
