import theme from '~/theme';
import { AGENT_STATUS, type AgentAlertLevel } from '~/types/agent';

const statusColorMap: Record<AGENT_STATUS, string> = {
  [AGENT_STATUS.alert]: theme.colors.red,
  [AGENT_STATUS.connectionLost]: theme.colors.midGrey,
  [AGENT_STATUS.inMission]: theme.colors.blue,
  [AGENT_STATUS.inSafeZone]: theme.colors.green,
};

export default function getAgentStatusColor(
  agent:
    | undefined
    | {
        status: AGENT_STATUS;
        alertLevel: AgentAlertLevel;
        isOffline: boolean | undefined;
      },
): string {
  if (agent?.alertLevel === 'critical') {
    return theme.colors.red;
  }

  if (agent?.alertLevel === 'warning') {
    return theme.colors.yellow;
  }

  if (agent?.alertLevel === 'information') {
    return theme.colors.primaryBlue;
  }

  if (agent?.isOffline) {
    return theme.colors.thinGrey;
  }

  return statusColorMap[agent?.status || AGENT_STATUS.connectionLost];
}
