import { Wrapper as GoogleMapsWrapper } from '@googlemaps/react-wrapper';
import { memo } from 'react';

import variables from '~/config/variables';

import MainMapElement, { type Props } from './MainMapElement';

const MainMap = memo(
  ({
    isLoading,
    isBlurred,
    initialZoom,
    initialLat,
    initialLng,
    followingZoom,
    followingLat,
    followingLng,
    isFocusedOnAgent,
  }: Props) => (
    // TODO: https://github.com/googlemaps/js-api-loader/issues/825
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <GoogleMapsWrapper apiKey={variables.googleMapsApiKey}>
      <MainMapElement
        key={`${isLoading}${isBlurred}`}
        isLoading={isLoading}
        isBlurred={isBlurred}
        initialZoom={initialZoom}
        initialLat={initialLat}
        initialLng={initialLng}
        followingZoom={followingZoom}
        followingLat={followingLat}
        followingLng={followingLng}
        isFocusedOnAgent={isFocusedOnAgent}
      />
    </GoogleMapsWrapper>
  ),
);

MainMap.displayName = 'MainMap';

export default MainMap;
