import { useReactiveVar } from '@apollo/client';
import { createContext, useContext, useMemo, type ReactNode } from 'react';

import useQueryWithSubscriptionSubsidiaryAlarms from '~/apollo/hooks/alarm/useQueryWithSubscriptionSubsidiaryAlarms';
import { currentSubsidiaryIdentifierVar } from '~/apollo/reactiveVariables/currentSubsidiaryIdentifierVar';
import { ALARM_LEVEL, type AlarmWithCarrier } from '~/types/alarm';
import isAlarmOngoing from '~/utils/alarm/isAlarmOngoing';

interface AlarmsContextType {
  alarms: AlarmWithCarrier[];
  ongoingAlarms: AlarmWithCarrier[];
  ongoingCriticalAlarms: AlarmWithCarrier[];
  ongoingWarningAlarms: AlarmWithCarrier[];
  ongoingInformationAlarms: AlarmWithCarrier[];
  historicalAlarms: AlarmWithCarrier[];
  isLoading: boolean;
  isInitialLoading: boolean;
  refetchAlarms: () => void;
  hasAlertBottomBanner: boolean;
}

const AlarmsContext = createContext<AlarmsContextType>({
  alarms: [],
  ongoingAlarms: [],
  ongoingCriticalAlarms: [],
  ongoingWarningAlarms: [],
  ongoingInformationAlarms: [],
  historicalAlarms: [],
  isLoading: true,
  isInitialLoading: true,
  refetchAlarms: () => undefined,
  hasAlertBottomBanner: false,
});

AlarmsContext.displayName = 'AlarmsContext';

export function AlarmsContextProvider({ children }: { children: ReactNode }) {
  const currentSubsidiaryIdentifier = useReactiveVar(currentSubsidiaryIdentifierVar);

  const { alarms, isLoading, refetchAlarms } = useQueryWithSubscriptionSubsidiaryAlarms({
    subsidiaryId: currentSubsidiaryIdentifier,
  });

  const ongoingAlarms = useMemo(() => alarms.filter((alarm) => isAlarmOngoing(alarm)), [alarms]);

  const ongoingCriticalAlarms = useMemo(
    () => ongoingAlarms.filter((alarm) => alarm.level === ALARM_LEVEL.Critical),
    [ongoingAlarms],
  );

  const ongoingWarningAlarms = useMemo(
    () => ongoingAlarms.filter((alarm) => alarm.level === ALARM_LEVEL.Warning),
    [ongoingAlarms],
  );

  const ongoingInformationAlarms = useMemo(
    () => ongoingAlarms.filter((alarm) => alarm.level === ALARM_LEVEL.Information),
    [ongoingAlarms],
  );

  const historicalAlarms = useMemo(
    () => alarms.filter((alarm) => !isAlarmOngoing(alarm)),
    [alarms],
  );

  const hasAlertBottomBanner = ongoingAlarms.length > 0;

  const value: AlarmsContextType = useMemo(
    () => ({
      alarms,
      ongoingAlarms,
      ongoingCriticalAlarms,
      ongoingWarningAlarms,
      ongoingInformationAlarms,
      historicalAlarms,
      isLoading,
      isInitialLoading: isLoading && alarms.length === 0,
      refetchAlarms,
      hasAlertBottomBanner,
    }),
    [
      alarms,
      ongoingAlarms,
      ongoingCriticalAlarms,
      ongoingWarningAlarms,
      ongoingInformationAlarms,
      historicalAlarms,
      isLoading,
      refetchAlarms,
      hasAlertBottomBanner,
    ],
  );

  return <AlarmsContext.Provider value={value}>{children}</AlarmsContext.Provider>;
}

export default function useAlarmsContext() {
  return useContext(AlarmsContext);
}
