import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { Button, Form, Input, Modal, Radio, Select, Space, Switch } from 'antd';
import merge from 'lodash/merge';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import { memo, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import useMutationSetConfigurationDeviceDefault from '~/apollo/hooks/configurationDevice/useMutationSetConfigurationDeviceDefault';
import useMutationSetConfigurationDeviceOverride from '~/apollo/hooks/configurationDevice/useMutationSetConfigurationDeviceOverride';
import ConfigurationConfigurationToggleFormItem from '~/components/forms/ConfigurationToggleFormItem';
import { DEFAULT_BASE_DEVICE_CONFIGURATION } from '~/config/defaults';
import useAgentsContext from '~/context/useAgentsContext';
import useBrainConfigurationsContext from '~/context/useBrainConfigurationsContext';
import useCompany from '~/hooks/useCompany';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { CONFIGURATION_SCOPE_LEVEL, type ConfigurationClientScope } from '~/types/configuration';
import type {
  DeviceConfiguration,
  DeviceConfigurationOverride,
  WbrOrientation,
} from '~/types/configurationDevice';
import type { ModalProps } from '~/types/modal';
import doesValueExist from '~/utils/configurations/doesValueExist';
import getConfigurationScope from '~/utils/configurations/getConfigurationScope';
import notification from '~/utils/notification';
import curateUrl from '~/utils/parse/curateUrl';
import getYesOrNo from '~/utils/parse/getYesOrNo';

const TopGridDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  ${theme.medias.lteSmall} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const BottomActionsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OrientationsGridDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));

  ${theme.medias.lteSmall} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const SlBottomGridDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  ${theme.medias.lteSmall} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const VISIBLE_BRAIN_ORIENTATIONS: WbrOrientation[] = [
  'vertical_A',
  'vertical_B',
  // 'horizontal_A',
  'horizontal_B',
];

type FormValues = Partial<DeviceConfiguration>;

interface EnabledFormValues {
  wbr_orientation: boolean;
  delay_disconn_sec: boolean;
  delay_bad_conn_sec: boolean;
  embedded_sound: boolean;
  gps_pr: boolean;
  physio_pr: boolean;
  env_pr: boolean;
  lte_debug_mode: boolean;
  sl: Record<string, boolean>;
}

function getEnabledFormValues({
  isGlobal,
  existingConfiguration,
}: {
  isGlobal: boolean;
  existingConfiguration: DeviceConfiguration | undefined;
}): EnabledFormValues {
  return {
    wbr_orientation: isGlobal || doesValueExist(existingConfiguration?.wbr_orientation),
    delay_disconn_sec: isGlobal || doesValueExist(existingConfiguration?.delay_disconn_sec),
    delay_bad_conn_sec: isGlobal || doesValueExist(existingConfiguration?.delay_bad_conn_sec),
    embedded_sound: isGlobal || doesValueExist(existingConfiguration?.embedded_sound),
    gps_pr: isGlobal || doesValueExist(existingConfiguration?.gps_pr),
    physio_pr: isGlobal || doesValueExist(existingConfiguration?.physio_pr),
    env_pr: isGlobal || doesValueExist(existingConfiguration?.env_pr),
    lte_debug_mode: isGlobal || doesValueExist(existingConfiguration?.lte_debug_mode),
    sl: DEFAULT_BASE_DEVICE_CONFIGURATION.sl.reduce(
      (acc, shadow) => ({
        ...acc,
        [shadow.name]:
          isGlobal ||
          doesValueExist(existingConfiguration?.sl.find((sl) => sl.name === shadow.name)),
      }),
      {},
    ),
  };
}

export interface BrainConfigurationAddEditModalProps {
  isEdit: boolean;
  itemScope: ConfigurationClientScope;
  itemId: string | undefined;
  isBackofficePage: boolean;
}

const BrainConfigurationAddEditModal = memo(
  ({
    isOpen,
    onClose,
    isEdit,
    itemScope,
    itemId,
    isBackofficePage,
  }: ModalProps & BrainConfigurationAddEditModalProps) => {
    const { currentCompany } = useCompany();
    const { currentSubsidiary } = useSubsidiary();

    const [selectedAgentId, setSelectedAgentId] = useState<string | null>(
      itemScope === 'agent' ? itemId || null : null,
    );

    const [selectedScope, setSelectedScope] = useState<CONFIGURATION_SCOPE_LEVEL>(
      getConfigurationScope(itemScope),
    );

    const isGlobal = selectedScope === CONFIGURATION_SCOPE_LEVEL.platform;

    const { agents, isLoading: isAgentsContextLoading } = useAgentsContext();

    const {
      brainConfigurations,
      isLoading: isBrainConfigurationsContextLoading,
      refetchBrainConfigurations,
    } = useBrainConfigurationsContext();

    const {
      setConfigurationDeviceDefault,
      isLoading: isMutationSetConfigurationDeviceDefaultLoading,
    } = useMutationSetConfigurationDeviceDefault();

    const {
      setConfigurationDeviceOverride,
      isLoading: isMutationSetConfigurationDeviceOverrideLoading,
    } = useMutationSetConfigurationDeviceOverride();

    const isLoading =
      isAgentsContextLoading ||
      isBrainConfigurationsContextLoading ||
      isMutationSetConfigurationDeviceDefaultLoading ||
      isMutationSetConfigurationDeviceOverrideLoading;

    const existingGlobalPlatformConfig = useMemo(
      () =>
        brainConfigurations.find(
          (config) => (config as DeviceConfigurationOverride)?.scope?.id === undefined,
        ),
      [brainConfigurations],
    );

    const existingCurrentCompanyConfig = useMemo(
      () =>
        brainConfigurations.find(
          (config) => (config as DeviceConfigurationOverride)?.scope?.id === currentCompany?.id,
        ),
      [brainConfigurations, currentCompany?.id],
    );

    const existingCurrentSubsidiaryConfig = useMemo(
      () =>
        brainConfigurations.find(
          (config) => (config as DeviceConfigurationOverride)?.scope?.id === currentSubsidiary?.id,
        ),
      [brainConfigurations, currentSubsidiary?.id],
    );

    const existingConfiguration = useMemo(() => {
      switch (selectedScope) {
        case CONFIGURATION_SCOPE_LEVEL.company:
          return existingCurrentCompanyConfig;
        case CONFIGURATION_SCOPE_LEVEL.subsidiary:
          return existingCurrentSubsidiaryConfig;
        case CONFIGURATION_SCOPE_LEVEL.agent:
          return brainConfigurations.find(
            (config) => (config as DeviceConfigurationOverride)?.scope?.id === selectedAgentId,
          );
        case CONFIGURATION_SCOPE_LEVEL.platform:
        default:
          return existingGlobalPlatformConfig;
      }
    }, [
      brainConfigurations,
      existingGlobalPlatformConfig,
      existingCurrentCompanyConfig,
      existingCurrentSubsidiaryConfig,
      selectedAgentId,
      selectedScope,
    ]);

    const [formValues, setFormValues] = useState<FormValues>(
      merge(
        {
          activated: true,
          wbr_orientation: DEFAULT_BASE_DEVICE_CONFIGURATION.wbr_orientation,
          delay_disconn_sec: undefined,
          delay_bad_conn_sec: undefined,
          embedded_sound: false,
          gps_pr: undefined,
          physio_pr: undefined,
          env_pr: undefined,
          lte_debug_mode: false,
          sl: [],
        },
        existingConfiguration,
      ),
    );

    useEffect(() => {
      if (existingConfiguration) {
        setFormValues(existingConfiguration);
      }
    }, [existingConfiguration]);

    const agentOptions = useMemo(
      () =>
        orderBy(
          agents.map((agent) => {
            const label = `${agent.completeName}${agent.email ? ` (${agent.email})` : ''}`;
            return {
              value: agent.id,
              label,
              labelNormalized: label.toLocaleLowerCase(),
              disabled: brainConfigurations.some(
                (config) => (config as DeviceConfigurationOverride)?.scope?.id === agent.id,
              ),
            };
          }),
          ['labelNormalized'],
          ['asc'],
        ),
      [agents, brainConfigurations],
    );

    const [enabledFormValues, setEnabledFormValues] = useState<EnabledFormValues>(
      getEnabledFormValues({ isGlobal, existingConfiguration }),
    );

    useEffect(() => {
      setEnabledFormValues(getEnabledFormValues({ isGlobal, existingConfiguration }));
    }, [isGlobal, existingConfiguration]);

    const handleSubmit = async () => {
      try {
        const formValuesConfiguration: DeviceConfiguration = {
          activated: formValues.activated || false,
          wbr_orientation: enabledFormValues.wbr_orientation
            ? formValues.wbr_orientation ?? null
            : null,
          embedded_sound: enabledFormValues.embedded_sound
            ? formValues.embedded_sound ?? null
            : null,
          delay_bad_conn_sec: enabledFormValues.delay_bad_conn_sec
            ? formValues.delay_bad_conn_sec ?? null
            : null,
          delay_disconn_sec: enabledFormValues.delay_disconn_sec
            ? formValues.delay_disconn_sec ?? null
            : null,
          physio_pr: enabledFormValues.physio_pr ? formValues.physio_pr ?? null : null,
          env_pr: enabledFormValues.env_pr ? formValues.env_pr ?? null : null,
          gps_pr: enabledFormValues.gps_pr ? formValues.gps_pr ?? null : null,
          lte_debug_mode: enabledFormValues.lte_debug_mode
            ? formValues.lte_debug_mode ?? null
            : null,
          sl: (formValues?.sl || []).map((shadow) => ({
            name: shadow.name,
            value: enabledFormValues.sl[shadow.name] ? shadow.value ?? null : null,
          })),
        };
        if (isGlobal) {
          await setConfigurationDeviceDefault({
            variables: {
              configuration: merge(DEFAULT_BASE_DEVICE_CONFIGURATION, formValuesConfiguration),
            },
          });
        } else {
          await setConfigurationDeviceOverride({
            configuration: formValuesConfiguration,
            companyId:
              selectedScope === CONFIGURATION_SCOPE_LEVEL.company ? currentCompany?.id : undefined,
            subsidiaryId:
              selectedScope === CONFIGURATION_SCOPE_LEVEL.subsidiary
                ? currentSubsidiary?.id
                : undefined,
            carrierId:
              selectedScope === CONFIGURATION_SCOPE_LEVEL.agent
                ? selectedAgentId || undefined
                : undefined,
          });
        }
        await refetchBrainConfigurations();
        onClose();
        const notificationDescriptionTranslation = isEdit
          ? i18n.t('configurationsGeneric.configurationUpdatedSuccess')
          : i18n.t('configurationsGeneric.configurationCreatedSuccess');
        notification.success({
          message: i18n.t('common.success'),
          description: `${notificationDescriptionTranslation} ${i18n.t('brainConfiguration.updatesWillTakeEffect')}`,
        });
      } catch (error) {
        const errorMessage = (error as any)?.message;
        notification.error({
          message:
            errorMessage === 'exactly 1 entity needs to be defined'
              ? i18n.t('configurationsGeneric.youNeedToSelectAnAgentToContinue')
              : errorMessage || i18n.t('common.error'),
        });
      }
    };

    const enabledCount = useMemo(() => {
      const { sl, ...rest } = enabledFormValues;
      const slCount = Object.values(sl).filter(Boolean).length;
      const restCount = Object.values(rest).filter(Boolean).length;
      return slCount + restCount;
    }, [enabledFormValues]);

    const createConfigurationTranslation = isGlobal
      ? i18n.t('configurationsGeneric.editGlobalConfiguration')
      : i18n.t('configurationsGeneric.editConfigurationOverride');

    const overridesCountTranslation = ` (${i18n.t('configurationsGeneric.overrides', {
      count: enabledCount,
    })})`;

    return (
      <Modal
        title={
          isEdit
            ? createConfigurationTranslation
            : i18n.t('configurationsGeneric.createConfiguration')
        }
        footer={null}
        centered
        width={800}
        open={isOpen}
        onCancel={onClose}
      >
        <Form
          layout="vertical"
          validateTrigger="onBlur"
          initialValues={undefined}
          autoComplete="off"
          onFinish={handleSubmit}
        >
          <div style={{ height: '8px' }} />
          <TopGridDiv>
            <Form.Item label={i18n.t('common.scope')}>
              <Radio.Group
                onChange={(event) => {
                  setSelectedScope(event.target.value);
                }}
                value={selectedScope}
              >
                <Space direction="horizontal">
                  {/* isBackofficePage && (
                <Radio
                  value={CONFIGURATION_SCOPE_LEVEL.platform}
                  disabled={isEdit || !!existingGlobalPlatformConfig}
                >
                  {i18n.t('common.platform')}
                </Radio>
              ) */}
                  <Radio
                    value={CONFIGURATION_SCOPE_LEVEL.company}
                    disabled={isEdit || !!existingCurrentCompanyConfig}
                  >
                    {i18n.t('common.company')}
                  </Radio>
                  <Radio
                    value={CONFIGURATION_SCOPE_LEVEL.subsidiary}
                    disabled={isEdit || !!existingCurrentSubsidiaryConfig}
                  >
                    {i18n.t('common.subsidiary')}
                  </Radio>
                  <Radio value={CONFIGURATION_SCOPE_LEVEL.agent} disabled={isEdit}>
                    {i18n.t('common.agent')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {selectedScope >= CONFIGURATION_SCOPE_LEVEL.agent && (
              <Form.Item label={i18n.t('common.agent')}>
                <Select
                  loading={isLoading}
                  disabled={isEdit || !currentCompany?.id || !currentSubsidiary?.id}
                  options={agentOptions}
                  placeholder={i18n.t<string>('common.agent')}
                  value={selectedAgentId}
                  onChange={(value) => {
                    setSelectedAgentId(value);
                  }}
                />
              </Form.Item>
            )}
          </TopGridDiv>
          <Form.Item label={i18n.t('common.activated')}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '12px',
              }}
            >
              <Switch
                checked={formValues?.activated || false}
                disabled={!isBackofficePage && isGlobal}
                loading={isLoading}
                onChange={(checked) => {
                  setFormValues((prevFormValues) => ({
                    ...prevFormValues,
                    activated: checked,
                  }));
                }}
              />
              <span
                style={{ color: formValues?.activated ? theme.colors.green : theme.colors.red }}
              >
                {getYesOrNo(!!formValues?.activated)}{' '}
                {`(${formValues?.activated ? i18n.t('common.active') : i18n.t('common.inactive')})`.toLowerCase()}
              </span>
            </div>
          </Form.Item>
          <ConfigurationConfigurationToggleFormItem
            label={i18n.t('brainConfiguration.formValues.wbr_orientation')}
            checked={enabledFormValues.wbr_orientation}
            onChange={(isChecked) => {
              setEnabledFormValues((prev) => ({ ...prev, wbr_orientation: isChecked }));
            }}
            isGlobal={isGlobal}
          >
            <Radio.Group
              buttonStyle="solid"
              value={formValues.wbr_orientation}
              onChange={(event) => {
                setFormValues((prev) => ({ ...prev, wbr_orientation: event.target.value }));
              }}
            >
              <OrientationsGridDiv>
                {VISIBLE_BRAIN_ORIENTATIONS.map((orientation) => (
                  <div key={orientation}>
                    <Radio value={orientation} checked={formValues.wbr_orientation === orientation}>
                      {startCase(orientation)}
                    </Radio>
                    <img
                      style={{ marginTop: '0.5rem', maxWidth: '80%' }}
                      src={curateUrl(`/images/brainOrientation/${orientation}.png`)}
                      alt={orientation}
                    />
                  </div>
                ))}
              </OrientationsGridDiv>
            </Radio.Group>
          </ConfigurationConfigurationToggleFormItem>
          <ConfigurationConfigurationToggleFormItem
            label={i18n.t('brainConfiguration.formValues.embedded_sound')}
            checked={enabledFormValues.embedded_sound}
            onChange={(isChecked) => {
              setEnabledFormValues((prev) => ({ ...prev, embedded_sound: isChecked }));
              setFormValues((prev) => ({ ...prev, embedded_sound: isChecked }));
            }}
            isGlobal={isGlobal}
          >
            <Switch
              checked={formValues?.embedded_sound || undefined}
              disabled={false}
              loading={isLoading}
              onChange={(checked) => {
                setFormValues((prevFormValues) => ({ ...prevFormValues, embedded_sound: checked }));
              }}
            />
          </ConfigurationConfigurationToggleFormItem>
          {isBackofficePage && (
            <>
              <ConfigurationConfigurationToggleFormItem
                label={i18n.t('brainConfiguration.formValues.delay_bad_conn_sec')}
                checked={enabledFormValues.delay_bad_conn_sec}
                onChange={(isChecked) => {
                  setEnabledFormValues((prev) => ({ ...prev, delay_bad_conn_sec: isChecked }));
                }}
                isGlobal={isGlobal}
              >
                <Input
                  type="number"
                  placeholder={DEFAULT_BASE_DEVICE_CONFIGURATION.delay_bad_conn_sec?.toString()}
                  disabled={isLoading}
                  value={formValues?.delay_bad_conn_sec || undefined}
                  required={enabledFormValues.delay_bad_conn_sec}
                  onChange={(event) => {
                    setFormValues((prevFormValues) => ({
                      ...prevFormValues,
                      delay_bad_conn_sec: parseInt(event.target.value, 10),
                    }));
                  }}
                />
              </ConfigurationConfigurationToggleFormItem>
              <ConfigurationConfigurationToggleFormItem
                label={i18n.t('brainConfiguration.formValues.delay_disconn_sec')}
                checked={enabledFormValues.delay_disconn_sec}
                onChange={(isChecked) => {
                  setEnabledFormValues((prev) => ({ ...prev, delay_disconn_sec: isChecked }));
                }}
                isGlobal={isGlobal}
              >
                <Input
                  type="number"
                  placeholder={DEFAULT_BASE_DEVICE_CONFIGURATION.delay_disconn_sec?.toString()}
                  disabled={isLoading}
                  value={formValues?.delay_disconn_sec || undefined}
                  required={enabledFormValues.delay_disconn_sec}
                  onChange={(event) => {
                    setFormValues((prevFormValues) => ({
                      ...prevFormValues,
                      delay_disconn_sec: parseInt(event.target.value, 10),
                    }));
                  }}
                />
              </ConfigurationConfigurationToggleFormItem>
              <ConfigurationConfigurationToggleFormItem
                label={i18n.t('brainConfiguration.formValues.lte_debug_mode')}
                checked={enabledFormValues.lte_debug_mode}
                onChange={(isChecked) => {
                  setEnabledFormValues((prev) => ({ ...prev, lte_debug_mode: isChecked }));
                  setFormValues((prev) => ({ ...prev, lte_debug_mode: isChecked }));
                }}
                isGlobal={isGlobal}
              >
                <Switch
                  checked={formValues?.lte_debug_mode || undefined}
                  disabled={false}
                  loading={isLoading}
                  onChange={(checked) => {
                    setFormValues((prevFormValues) => ({
                      ...prevFormValues,
                      lte_debug_mode: checked,
                    }));
                  }}
                />
              </ConfigurationConfigurationToggleFormItem>
              <ConfigurationConfigurationToggleFormItem
                label={i18n.t('brainConfiguration.formValues.gps_pr')}
                checked={enabledFormValues.gps_pr}
                onChange={(isChecked) => {
                  setEnabledFormValues((prev) => ({ ...prev, gps_pr: isChecked }));
                }}
                isGlobal={isGlobal}
              >
                <Input
                  type="number"
                  placeholder={DEFAULT_BASE_DEVICE_CONFIGURATION.gps_pr?.toString()}
                  disabled={isLoading}
                  value={formValues?.gps_pr || undefined}
                  required={enabledFormValues.gps_pr}
                  onChange={(event) => {
                    setFormValues((prevFormValues) => ({
                      ...prevFormValues,
                      gps_pr: parseInt(event.target.value, 10),
                    }));
                  }}
                />
              </ConfigurationConfigurationToggleFormItem>
              <ConfigurationConfigurationToggleFormItem
                label={i18n.t('brainConfiguration.formValues.physio_pr')}
                checked={enabledFormValues.physio_pr}
                onChange={(isChecked) => {
                  setEnabledFormValues((prev) => ({ ...prev, physio_pr: isChecked }));
                }}
                isGlobal={isGlobal}
              >
                <Input
                  type="number"
                  placeholder={DEFAULT_BASE_DEVICE_CONFIGURATION.physio_pr?.toString()}
                  disabled={isLoading}
                  value={formValues?.physio_pr || undefined}
                  required={enabledFormValues.physio_pr}
                  onChange={(event) => {
                    setFormValues((prevFormValues) => ({
                      ...prevFormValues,
                      physio_pr: parseInt(event.target.value, 10),
                    }));
                  }}
                />
              </ConfigurationConfigurationToggleFormItem>
              <ConfigurationConfigurationToggleFormItem
                label={i18n.t('brainConfiguration.formValues.env_pr')}
                checked={enabledFormValues.env_pr}
                onChange={(isChecked) => {
                  setEnabledFormValues((prev) => ({ ...prev, env_pr: isChecked }));
                }}
                isGlobal={isGlobal}
              >
                <Input
                  type="number"
                  placeholder={DEFAULT_BASE_DEVICE_CONFIGURATION.env_pr?.toString()}
                  disabled={isLoading}
                  value={formValues?.env_pr || undefined}
                  required={enabledFormValues.env_pr}
                  onChange={(event) => {
                    setFormValues((prevFormValues) => ({
                      ...prevFormValues,
                      env_pr: parseInt(event.target.value, 10),
                    }));
                  }}
                />
              </ConfigurationConfigurationToggleFormItem>
              <SlBottomGridDiv>
                {DEFAULT_BASE_DEVICE_CONFIGURATION.sl.map((shadow) => {
                  const updateFormValues = (checked: boolean) => {
                    const alreadyExists = (formValues?.sl || []).find(
                      (sl) => sl.name === shadow.name,
                    );
                    if (alreadyExists) {
                      setFormValues((prevFormValues) => ({
                        ...prevFormValues,
                        sl: (prevFormValues.sl || []).map((sl) =>
                          sl.name === shadow.name ? { ...sl, value: checked } : sl,
                        ),
                      }));
                    } else {
                      setFormValues((prevFormValues) => ({
                        ...prevFormValues,
                        sl: [...(prevFormValues.sl || []), { name: shadow.name, value: checked }],
                      }));
                    }
                  };
                  return (
                    <ConfigurationConfigurationToggleFormItem
                      key={shadow.name}
                      label={i18n.t(`brainConfiguration.formValues.sl.${shadow.name}`)}
                      checked={enabledFormValues.sl[shadow.name]}
                      onChange={(isChecked) => {
                        setEnabledFormValues((prev) => ({
                          ...prev,
                          sl: { ...prev.sl, [shadow.name]: isChecked },
                        }));
                        updateFormValues(isChecked);
                      }}
                      isGlobal={isGlobal}
                    >
                      <Switch
                        checked={
                          (formValues?.sl || []).find((sl) => sl.name === shadow.name)?.value ||
                          undefined
                        }
                        disabled={false}
                        loading={isLoading}
                        onChange={(checked) => {
                          updateFormValues(checked);
                        }}
                      />
                    </ConfigurationConfigurationToggleFormItem>
                  );
                })}
              </SlBottomGridDiv>
            </>
          )}

          <BottomActionsDiv>
            <Button size="middle" disabled={isLoading} onClick={onClose}>
              {i18n.t('common.cancel')}
            </Button>
            <Button
              size="middle"
              type="primary"
              htmlType="submit"
              loading={isLoading}
              icon={<SaveOutlined />}
              disabled={!isGlobal && enabledCount === 0}
            >
              {isEdit ? i18n.t('common.save') : i18n.t('common.create')}
              {isGlobal || !isBackofficePage ? '' : overridesCountTranslation}
            </Button>
          </BottomActionsDiv>
        </Form>
      </Modal>
    );
  },
);

BrainConfigurationAddEditModal.displayName = 'BrainConfigurationAddEditModal';

export default BrainConfigurationAddEditModal;
